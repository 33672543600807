import React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/theme/layout"
import Image from "../components/atoms/image"
import styled from "styled-components"
import SEO from "../components/organisms/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const ArchievePage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes("philosophy.jpg")
  })
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土-Archive"
        description="ジャグリングにおける言葉と実践 『ぴんとくるくる浄土』Season2 体験紀 |染谷樹、山下湧志朗、貫井洋介"
        picUrl={domain + image.node.childImageSharp.sizes.src}
      />
      <Article>
        <ArticleHeader>
          <HeaderWrap>
            <TitleMain><span>ジャグリングにおける言葉と実践</span>『ぴんとくるくる浄土』Season2 体験記｜染谷樹、山下湧志朗、貫井洋介</TitleMain>
          </HeaderWrap>
        </ArticleHeader>

        <Introduction>
          <p>
            ジャグリングという新しい思考=実践のスタイルをいかに広めるか?<br />
          その模索として、ピントクルは2020年夏にワークショップ「ぴんとくるくる浄土」を行った。<br />
          ここでは、このワークショップに参加した3名の体験記を掲載する。<br />
          ワークショップの中で、彼らはそれぞれがジャグリングに対して抱く疑問・問題を深め、最終的にパフォーマンスやプレゼンなどの形で作品を発表した。<br />
          体験記の文章の形式・内容は三者三様になったが、いずれも彼らが実践の中で辿った思考を追体験できるものとなっているのではないか。<br />
          </p>
        </Introduction>
        <Section>
          <ActivityTitle>
            「ジャグリングを通じて、自分を知ること」
          <span>染谷樹</span>
          </ActivityTitle>
          <div>
            <Part>
              ぴんとくるくる浄土Season2では、各々のジャグリング観の共有が参加者間で頻繁に行われた。その繰り返された共有とフィードバックにより、今までぼんやりと捉えていたジャグリングと改めて正面から向き合えたことが今回の収穫だと感じている。<br />
          本企画では、集大成として形式を問わない成果発表の場が用意されていた。そこで私は、この三ヶ月間を通じて再発見したジャグリングの魅力をテーマにワークショップを実施した。<br />
            </Part>

            <PartTitle>ジャグリングの魅力</PartTitle>
            <Part>
              私は、ジャグリングの魅力は「その人の本質が表れる」ことだと感じている。<br />
          人がジャグリングをしている様子には、思考から身体のつくりまで、その人の奥底の隠された姿が鮮明に映し出されており、その人間性を観察する作業がジャグリングを見るときの一番の楽しさであるように思える。<br />
          このようにジャグリングに人間性が表れる要因として、趣味嗜好や身体性の拡張を誘発するようなジャグリングの持つ特性が考えられる。<br />
            </Part>

            <PartTitle>―ジャグリングは物とのコミュニケーション</PartTitle>
            <Part>
              ジャグリングにおける最大の特徴は、物を扱うことだ。<br />
          物とは、自分自身ではない異物である。そのため、「物を扱う」という行為は言ってしまえば、自分以外の他者へアクションをしてそのフィードバックを得るまでの、一連のコミュニケーションである。そのため私は、ジャグリングの本質は物とコミュニケーションをとることにあるのではないか、と考えている。<br />
          そしてジャグリングにおける物とのコミュニケーションが、ハサミで紙を切る、ハンガーに服をかけるといった日常的に行われている「製品を使う」という行為と異なる点は、物の用途に縛られない、という点だ。<br />
          ジャグリングでは、同じ道具を扱うにしても、人によって全く使い方が異なる。例えば、同じボールを扱う場合でも、投げる人、体を転がす人、蹴る人、と様々だ。床を滑らせ流場合もある。時々、新たな使い方を発見する人がいれば話題にさえなる。つまり、ジャグリング的対物コミュニケーションにおいては、アプローチの難度の程度はあるが、原理的に物側から制約をかけられることはない。「ジャグリング」というフィルターを通すことによって、規定された用途という枠組から解放されるのだ。<br />
            </Part>

            <Wrap>
              <Image filename="taturu_a_1.jpg" style={{ maxWidth: 900, width: "45%", height: "45%" }} />
              <Image filename="taturu_a_2.jpg" style={{ maxWidth: 900, width: "45%", height: "45%" }} />
            </Wrap>
            <Wrap>
              <Image filename="taturu_a_3.jpg" style={{ maxWidth: 900, width: "45%", height: "45%" }} />
              <Image filename="taturu_a_4.jpg" style={{ maxWidth: 900, width: "45%", height: "45%" }} />
            </Wrap>

            <Part>
              そのため、どのような物を、どう扱うかは完全にジャグリングをする人に委ねられている。それは物とどういった関係性をとりたいかといった嗜好や、そのアプローチが可能か/不可能かといった身体性に依存しているため、結果としてジャグリングには、「その人らしさ」が表れてくる。<br />
              <br />
            </Part>

            <PartTitle>―対人コミュニケーションと対物コミュニケーション</PartTitle>
            <Part>
              対物コミュニケーションの特徴として、対人とのコミュニケーションと異なり、相手に主体性がないという点がある。<br />
          対人コミュニケーションの場合、当然、相手にも意識がある。そのため、相手のリアクションは時と場合によって異なる上に、予期しないリアクションを返してくる場合もある。よって、状況や相手の心情に合わせアプローチを変える必要性が出てくる。<br />
          しかし、対物コミュニケーションではその必要はない。物から得られるリアクションは自然現法則に則っているため常に一定であり、おおよそ予想の範疇に収まる。そして、こちらのアクションを拒絶することもない。そのため、ジャグリングを通じて行われる対物コミュニケーションでは、その人の「自然体」のアプローチが全て受け入れられる。コミュニケーションが繰り返されるうち、そのアプローチはエスカレートし、結果として、その人の行うジャグリングは「その人らしさ」が凝縮されたものになる。<br />
              <br />
            </Part>

            <PartTitle>ジャグリングを通じ自分を理解する</PartTitle>
            <Part>
              これまで、ジャグリングの魅力を「物とのコミュニケーション方法に表れるその人の本質の可視化」だと述べてきた。しかしこれは、他人のジャグリングを見る場合の魅力であり、自分でジャグリングを行う場合にはさらなる魅力が現れる。それは「自分を知ることができる」ことだ。<br />
          前述のように、ジャグリングは行為者の自然体の結晶であるため、言ってしまえば、そこで行われる対物コミュニケーションは、当人にとっては「そうなって当然の関係性」である。そのため、自分のジャグリングを客観視することは困難である。<br />
          そして、他人のジャグリングを見たとき、初めて自分のジャグリングを理解することができる。物の選択、物との関係性から見える他人の自然体を自分のそれと比較し、そこで初めて自分の自然体、自分らしさに気がつく。自分はどういった物を好むのか、どういったコミュニケーションをとるのかを自覚することは、用途を定められた製品を扱う場合には得られない体験だ。<br />
              <br />
            </Part>

            <PartTitle>ワークショップ「物の使い方で自分の特性を知る」の結果と今後の展望</PartTitle>
            <Part>
              私は今回、上記のジャグリングの魅力を再確認するため、成果発表として「物の使い方で自分の特性を知る」というワークショッップを実施した。内容は、参加者全員に同じ道具で遊んでもらい、各々が思いついた動きを発表してもらう、というものだ。<br />
          結果は良好だった。全員が各々の特性に沿った使い方を披露し、その違いを見ることができた。山下さん、貫井さん、中西さんというジャグラー三人を対象に、フラワースティックという棒状のジャグリング道具をテーマに実施したが、山下さんはそれを投げ、貫井さんは体に乗せ揺らし、中西さんは床を利用し端部を身体に引っ掛けるといった使い方を披露した。<br />
          今回のワークショップの反省点に、参加者がジャグラーであった点が挙げられる。すでにジャグリング的コミュニケーションを身につけており、物を観察しそれを自由に扱うという心構えや、技術が既に高かった。そのため、いかにスキルフルに扱うかといった考え方が主体になってしまった。<br />
          そこで次に新たな疑問が湧いた。それは、ジャグラーのような物と自由に関わることに慣れた人間ではない非ジャグラーではどのように扱うのだろうか、というものだ。おそらく、物を前にした時、まず「これはどのように使う道具なのだろう」と使い方の正解を考えてしまうのではないだろうか。<br />
          しかし、そのような物との関わり方、コミュニケーションの可能性を広げるのがジャグリングだ。ジャグリングを経験していない、一般的な人が用途から解放され、まっさらな状態で物と向き合った時、どのような行動をとるのか、その初動にその人の本質が現れるにちがいない。<br />
          次の機会では、ぜひそこを見てみたい。<br />
            </Part>

            <div>
              <span>染谷樹(Someya Tatsuru)</span>
              <div>
                1993年生まれ。ジャグラー。
                身の回りに溢れる様々な物とコミュニケーションをとることを目指し、日用品を用いたジャグリングを行う。現在は靴をメインに扱い活動中。
            </div>
            </div>
          </div>
        </Section>
        <Section>
          <ActivityTitle>「ポイの補遺 ～紐の違いによる反発率変化の定量評価～」 <span>山下湧志朗</span></ActivityTitle>
          <div>
            <PartTitle>1 背景</PartTitle>
            <Part>
              ぴんとくるくる浄土Season2にて、「自作道具のＰｏＣ」と題してバウンスポイの道具作りおよび技作りについてのプレゼン発表を行った。道具製作の過程で「紐の素材によってポイの弾み方がかなり異なる」という知見が得られたが、具体的にどの程度異なるのか、という定量的な評価はできていなかった。<br />
            </Part>

            <PartTitle>2 目的</PartTitle>
            <Part>
              本稿では、簡易的にバウンスの高さを計測・分析し定量評価することで、バウンスポイ作りに役立つ法則性を探す。これにより計算でバウンスポイの設計をある程度見積もることができるようになり、製作時の試行錯誤数を減らすことで、製作を始めるハードルが更に下がることが期待できる。<br />
            </Part>

            <PartTitle>3 手法</PartTitle>
            <Part>
              プレゼン発表を学術的な研究発表のような雰囲気で行ったこともあり、せっかくなので科学実験のような形式で実験および報告を行う。ごっこ遊びのようなものであるが、ジャグリング界隈ではこのようなアプローチでの発表がまだ比較的少ないため、今後ありうる発表形式のひとつとして選択肢を提示する意味合いもある。また、くるくる浄土が座学と演習によるゼミ形式で行われたこととも相性が良い。<br />
          以下に具体的な実験方法を説明する。高さ180cmの位置でバウンスポイを水平に持ち、水平のまま自由落下させた際の地面からの跳ね上がり距離を計測した。距離の測定は、固定カメラで撮影した動画で画面上での距離を測定することで行った。なお動画の再生には「ＶＬＣメディアプレイヤー」というフリーソフトのコマ送り機能を使用し、画面上での距離測定には「測ルンです」というフリーソフトの二点間測定機能を使用した。<br />
          実験場所は近所の公園、バウンスさせる地面は乾いた土であった。図１に、距離測定の様子を示す。実験に使用したバウンスポイは自作のものであり、構造はプレゼン発表で紹介したとおりのものである（図２）。ヘッドはＧフォース(アトミック）70mmで固定とし、紐の長さは43cmで固定、紐の太さと素材は実験条件によって変更した。<br />
            </Part>

            <Figure>
              <Image filename="yama_zu_1.png" />
              <Caption>図1 画面上での距離測定の様子</Caption>
            </Figure>
            <Figure>
              <Image filename="yama_zu_2.jpg" />
              <Caption>図2 測定に使用したバウンスポイ</Caption>
            </Figure>

            <PartTitle>4 実験結果および考察</PartTitle>
            <SubTitle>4.1 紐の素材による違い</SubTitle>
            <Part>
              道具製作時に購入した三種類の素材について、それぞれの跳ね上がり距離を測定、比較した。使用した素材は綿（直径5mm）、ダイニーマ（直径8mm）、ダクロン（直径10mm）である。なおダイニーマはポリエチレン、ダクロンはポリエステルの商標のひとつである。また紐の硬さは、個人的な感覚ではダクロン > ダイニーマ > 綿 である。<br />
          測定はそれぞれ十回ずつ行い、初期高さと跳ね上がり距離の比を反発率として計算した。反発率の平均値および最低値、最高値を求め、紐の直径と反発率の関係を図３にプロットした。プロット点が平均値、エラーバーが最低値および最高値、点線が近似直線を示している。
          </Part>
            <Figure>
              <Image filename="yama_zu_3.png" />
              <Caption>図3</Caption>
            </Figure>
            <Part>
              紐直径を横軸としてみたところ、直径に比例して反発率が下がる様子が見られた。単純に考えれば紐の重さによりポイが跳ね上がりにくくなるため、紐の重量に比例して反発率が下がりそうなものだが、実験結果の三点は直径に比例する様子となった。そこで重量と反発率の関係を確認するため、それぞれの紐について重量を量り、横軸としたプロットを図４に示した。図４では紐重量も反発率と比例している様子が見られ、</Part>

            <Figure>
              <Image filename="yama_zu_4.png" />
              <Caption>図4</Caption>
            </Figure>
            <Part>
              感覚的にはこちらの結果が妥当に思える。図３では紐の素材による密度の違い等で、偶然にも直径の比と質量の比がほぼ一致していただけの可能性がある。いずれにせよ、三点ではデータが少なすぎるうえ、素材を揃えて太さを変えたときのデータがないと考察は難しい様子である。<br />
            </Part>

            <SubTitle>4.2 ワッシャーによる違い</SubTitle>
            <Part>
              先の実験で、紐の質量に比例して反発率が下がることが示唆された。そこで次は、同じ紐にワッシャーを追加していくことで、単純に重量の変化に対する反発率の変化を確認する実験を行う。紐は綿、ワッシャーはM10×22の鋼鉄ワッシャーとした。結果を図５に示す。プロット形式は先の実験と同様である。
          </Part>
            <Figure>
              <Image filename="yama_zu_5.png" />
              <Caption>図5</Caption>
            </Figure>
            <Part>
              図５より、多少の上下はあるが基本的にはワッシャー数に比例して反発率が下がっており、反発率は単純に紐の重量に比例して下がるという仮説を補強する結果となった。なおワッシャーが一枚あたり3.4g程度であり、近似直線の傾きがマイナス 1.14 であるため、1g あたり約 0.34% の反発率の低下を起こしていると考えられる。綿の紐の重量が44gであるため、この法則に従うとすれば紐が0gのときの反発率は73%程度となる。Ｇフォース（アトミック）の販売元であるPlay社の商品販売ページ１によればこのボールの反発率は90%であり、ボールに布を巻いて公園の地面で弾ませた時点で17%もの反発率を失っていると考えることもできる。
        さらにワッシャーの実験から得られた反発率[%] = 73 − 0.34 × 紐重量 [g] という式と図４の結果を比較するため、図６を作成した。</Part>
            <Figure>
              <Image filename="yama_zu_6.png" />
              <Caption>図6</Caption>
            </Figure>
            <Part>
              式の数値が赤線、その他は図４と同様である。この比較より、計算式は反発率の平均値よりもむしろ、最大値の見積もりに有用であると解釈できる。確かに測定時の値の幅は人為的なミスや外乱によるものがほとんどであるため、道具の特性を調べるならば平均値よりも最大値の方が適切かもしれない。<br />
          反発率が紐重量に支配されるとすれば、紐直径は素材の密度によって任意に選択できるはずである。例えば、太い紐を使いたければ密度の低い素材を選ぶ、といった具合で、自分の好みに合わせてポイを構成することができる。紐の硬さなどは、少なくとも自由落下でのバウンスに関しては重量ほどの影響を与えていない様子である。<br />
            </Part>

            <PartTitle>5 まとめ</PartTitle>
            <Part>
              バウンスポイの紐の違いによる反発率の変化を、簡単な測定により調査した。結果として、紐の重量におおよそ比例して反発率の最大値が下がっていく様子が観測できた。このことから、(実際の反発率は床の素材や布の厚さにもよるが、）紐の重量を決めることでおおよその反発率を見積もれる可能性が示唆された。<br />
          ポイに使う紐の素材を選ぶ際は、自身にあった紐の長さや太さを決めれば紐の体積が決まるため、必要な反発率を実現できる密度の素材を探すことで、効率的な製作ができると考えられる。<br />
            </Part>

            <PartTitle>6 感想</PartTitle>
            <Part>
              非常に雑な測定ではあったが、思ったよりも解釈の余地があるデータが得られて楽しかった。本稿執筆中に、とあるディアボリストによって「写真を撮って角度を測ることでディアボロの紐の摩擦係数を求めよう」というようなnoteが投稿されており、似たようなことをやっている人がいて嬉しいやら、二番煎じみたいになりそうで悔しいやら、複雑な心中である。<br />
            </Part>
          １<A href="https://www.playjuggling.com/en/bouncing-balls/173-1741-g-force-ball.html">https://www.playjuggling.com/en/bouncing-balls/173-1741-g-force-ball.html</A>
          </div>
          <div>
            <span>山下湧志朗（Yamashita Yushiro）</span>
            <div>
              1995年生まれ。クラブジャグラー。
              個人の特殊な技術ではなく、新たな発想によって生み出される簡単で新鮮な技に興味がある。
          </div>
          </div>
        </Section>
        <Section>
          <ActivityTitle>「不可能な赤 作品後記」<span>貫井洋介</span></ActivityTitle>
          <div>
            <NukiiImage filename="nukii_a_1.jpg" />
            <PartTitle>・音楽</PartTitle>
            <Part>
              アニメ作品『カウボーイビバップ』、テレビ版最終話「よせあつめブルース」から音声のみ拝借した。本作品は当時の社会情勢の煽りを受けた形でテレビ放映が打ち切りになってしまったのだが、最終話はキャラクターたちがこの規制に対して皮肉交じりに抗議するという非常に挑戦的な内容だった。音楽とセリフのバランスが非常によく、セリフ入りMADあるいはポエトリーリーディングの様相を呈している。<br />
          ところで、座学の最中、講師の山下耕平さんから「カラオケジャグリング」という言葉を聞いた。ざっくりといえば、昨今の学生ジャグリングシーンで見られる、J-POPを中心とした楽曲に合わせてジャグリングを行うパフォーマンスは、文化的にカラオケと近接しているという話だったと思う。はじめて「カラオケジャグリング」という単語を聞いたときはそのセンセーショナルな響きに驚いたが、同時に腑に落ちる感覚があった。出典を伺ったところ、円堂都司昭氏の著作『ソーシャル化する音楽　「聴取」から「遊び」へ』からとのこと。早速購入して読んでみた。2000年代日本におけるポップ・ミュージックの歩みを論じたもので、詳細は割愛するが、自分がジャグリングルーチンという形式でやりたいことは、書中の「音楽との合体」に他ならないと理解できた。今回の発表でも、朗読音楽とジャグリングを同期させる遊びに真剣に取り組みたいというひそかな思惑があった。<br />
          EDは元の楽曲と差し替えて、神聖かまってちゃん「フロントメモリー」。作成時は夏だったので。
          </Part>
            <NukiiImage filename="nukii_a_2.jpg" />
            <PartTitle>・ボールの４つの扱い方</PartTitle>
            <Part>
              今作の主要なテーマ。<br />
          私たちジャグラーは、ボールを「乗せる」「転がす」「弾く」「挟む」の４種類の扱い方で操っている、という考え方。
          </Part>
            <NukiiImage filename="nukii_a_3.jpg" />
            <PartTitle>・氷</PartTitle>
            <Part>
              とにかく練習に苦労した。10月の秋口に、上半身裸で氷を体に触れさせるのだから寒いに決まっている。それに居室で練習すると床が水浸しになってしまうので、浴室でしか練習できないことも面倒だった。<br />
          ただ、苦労は多かったが、なかなか面白い道具なのではないかと思う。「不可能な赤」の作中で登場するボール以外の道具（氷、タオル）は、ボールの4つの扱い方である「乗せる」「転がす」「弾く」「挟む」のうちどれかに特化しているが他はてんでダメという特徴をもつものを選んでいる。氷は明確に、「転がす」に特化した道具だ。厳密には転がっているのではなく、滑って移動させている。逆に、ぬるりと滑ってしまうので、「乗せる」「挟む」はやりにくい。手で掴むのも少しコツがいる。<br />
          いろいろな大きさ・形の氷を試してみたが、ジャグリングボールくらいの大きさは大きすぎるようだ。また球形よりも少し扁平なほうがよい。どちらの場合も、乗せているだけで勝手に滑り落ちて操作不可になってしまうからだ。<br />
          溶けて変形していくのは面白くはあるが扱いにくくもある。体中に油を塗りたくって何らかのオブジェクトを滑らせるほうが再現性は高いかもしれない（練習及び発表の難しさは上がる気もする）。
          </Part>
            <NukiiImage filename="nukii_a_4.jpg" />
            <PartTitle>・タオル</PartTitle>
            <Part>
              「乗せる」に特化した道具。「転がす」は全くできない、面白い。「弾く」と「挟む」は案外できそうだった。ビーンバッグとジャグリングスカーフの間で見つかった道具だったが、なかなかジャグリングするのに現実的ではないかと思う。<br />
             ちなみにボール以外の道具は他にもいくつかの候補があった。やじろべえ、起き上がりこぼし、粘土、スライム、羽、水滴、風船など。
          </Part>
            <NukiiImage filename="nukii_a_5.jpg" />
            <PartTitle>・ボールというジャグリング道具</PartTitle>
            <Part>
              「技術の枠組みの向こうに道具の枠組みを見る」という作品紹介タイトルから分かるように、当初は心中に、ボールという道具の範囲・輪郭を示そうという目論見があったと思う。この4つの扱い方がやりやすい道具がボールなんですよ、4つのうちどれかの扱い方があまりにやりにくいとボールとは違ってきますね…という風に。<br />
            講師の中西さんに作品紹介へのアドバイスをいただいているうちに、結局ボールといえる範囲みたいなものへの言及は控えることにした。それよりも、僕が着目している4つの扱い方（技術）に重点を置いたほうが本質的だし生産的だと思ったからだ。<br />
            ところで「不可能な赤」というタイトルには、これらの4つの扱い方をすべて100％引き出せるボールは現状存在しない、という今作の出発点となる着想が込められている。
          </Part>
            <PartTitle>・室内でのジャグリング</PartTitle>
            <Part>
              以前鑑賞した演劇にこんなものがあった。<br />
            舞台設定は喫茶店で、実際に現実の喫茶店を貸し切って公演を行う。登場人物たちは喫茶店の客なので出ハケは店のドアを使うし、店内の席に座って会話・ストーリーが進行する。僕が印象に残っているのは、観客も同じくその店の客かのように席に座って飲み物をいただきながら劇を鑑賞することだった。なんというか、観劇以外になにか意味・目的のある空間（この場合喫茶店）に、少しだけずれた次元に演者と観客が同居していて、観客が演者の営みを「盗み見る」感覚が絶妙だった。<br />
            それ以来、居室で演者と観客が同居するジャグリングパフォーマンスに憧れを抱いている気がする。今回のくるくる浄土では、オンライン配信という形ではあったものの、その真似事のようなものを実施してみた。
          </Part>
          </div>
          <div>
            <span>貫井洋介（Nukii Yosuke）</span>
            <div>
              1995年生まれ。ボールジャグラー。
              ボールと身体が触れ合うときの不思議な面白さを携えて舞台に立ちたい。
              京都大道芸倶楽部Juggling Donuts出身。
          </div>
          </div>
        </Section>
      </Article>
    </Layout >
  )
}

export default ArchievePage

export const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`

const Article = styled.article`
  max-width: 810px;
  margin: 0 auto 15rem;
  p {
    color: #212121;
  }
`;

const ArticleHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 calc(50% - 50vw);
  height: 400px;
  background:url("http://localhost:8000/static/090a5a37d7297f7c24eb0cf2e4301d9b/a7715/philosophy.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 768px) {
   height: 280px;
  }
`;

const HeaderWrap = styled.div`
width: 100vw;
height: 100%;
background: rgba(255, 255, 255, 0.5);
display: flex;
justify-content: center;
align-items: center;
`

const Section = styled.section`
  margin: 0 0 10rem;
  padding: 0 1rem;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    /* margin: 80px 0 60px; */
  }
`

const TitleMain = styled.h1`
  padding: 0 .8rem;
  text-align: center;
  >span {
    display: block;
    font-size: 16px;
  }
  font-size: 1.825rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.8;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
   font-size: 1.45rem;
   >span {
     font-size: 14px;
   }
  }
`

const Introduction = styled.div`
  padding: 6rem 1rem;
`

const ActivityTitle = styled.h2`
  font-size: 1.525rem;
  font-weight: 700;
  line-height: 1.8;
  display: block;
  margin-bottom: 3rem;
  text-align: center;
  >span {
    display: block;
    font-size: 1.225rem;
    margin-top: 4px;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.325rem;
    >span {
      font-size: 1.125rem;
    }
  }
`
const PartTitle = styled.h3`
  font-size: 1.125rem;
  line-height: 1.75;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid;
`

const SubTitle = styled.h4`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const Part = styled.p`
  margin-bottom: 2.5rem;
`

const NukiiImage = styled(Image)`
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
`
const Wrap = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px auto;
`
const A = styled.a`
  text-decoration: underline;
`

const Figure = styled.figure`
  max-width: 560px;
  margin: 0 auto 2rem;
`

const Caption = styled.figcaption`
  display: block;
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
  &:before {
    content: '▲';
    display: inline-block;
    margin-right: 4px;

  }
`;
